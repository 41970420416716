<template>
  <div>
    <el-dialog
      title="我管理的机构"
      :visible.sync="organDialog"
      width="500px"
      @close="close"
      center
      :show-close="showClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <ul v-if="organList.length>0" class="organList">
        <li v-for="(item,index) in organList" :key="index" class="ow" @click="getList(item)">
          <img v-if="item.image" :src="item.image" class="fl" />
          <img v-else src="https://www.weitianshi.cn/assets/img/newlogo.5a32b2a.png" class="fl" />
          <span class="fl" v-if="item.name">{{item.name}}</span>
          <span class="fl" v-else>暂无名称</span>
        </li>
      </ul>
      <div v-else class="noList">
        <span>暂无机构管理列表</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "Dialog",
  props: {
    organDialog: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    organList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showClose: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {
      nowPage: 1
    };
  },
  methods: {
    //发送关闭弹窗事件
    close() {
      this.$emit("close", {});
    },
    //分页切换
    currentPageChange(p) {
      this.nowPage = p;
      this.$emit("getPage", this.nowPage);
    },
    //点击获取列表
    getList(item) {
      this.$emit("getList", item);
    }
  }
};
</script>
<style lang="less">
.organList {
  li {
    display: flex;
    flex-wrap: wrap;
    // margin-bottom: 10px;
    cursor: pointer;
    img {
      width: 100px;
      height: 100px;
      margin: 5px 50px;
    }
    span {
      line-height: 100px;
      text-align: center;
    }
  }
}
.ow {
  overflow: hidden;
}
</style>

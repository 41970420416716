<template>
	<el-container>
		<el-header class="admin_index_top">
			<div class="display_flex">
				<div class="display_flex">
					<div>
						<img class="logo_class" :src="logo" alt :onerror="defaultImg" />
					</div>
					<div class="organization_name">
						{{systemName}}
						<span v-show="systemName&&organizationName">——</span>
						{{organizationName}}
					</div>
				</div>
				<div>
					<el-dropdown @command="showselsect">
						<span class="el-dropdown-link">
							{{userName?userName:'暂无姓名'}}
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="edit">修改密码</el-dropdown-item>
							<el-dropdown-item command="changeTl">更换机构</el-dropdown-item>
							<el-dropdown-item command="quit">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</el-header>
		<el-container class="section">
			<el-aside width="200px">
				<LeftMenu />
			</el-aside>
			<el-main>
				<router-view></router-view>
			</el-main>
		</el-container>
		<!-- 机构弹框组件 -->
		<organizationDialog :organDialog="organDialog" @close="close" @getPage="getPage" :organList="organList" :pageData="pageData" @getList="getList" :showClose="true" />
	</el-container>
</template>

<script>
	import organizationDialog from "@/components/Dialog"; //机构弹框组件
	import	LeftMenu from "./components/leftMenu.vue";
	import {
		error
	} from "@/utils/notification";
	export default {
		components: {
			organizationDialog,
			LeftMenu
		},
		data() {
			return {
				systemName: "", // 系统名称
				organizationName: "", // 机构名称
				defaultImg: "",
				logo: "",
				userName: "",
				selsects: false,
				page: 1, //初始分页
				organDialog: false, //机构弹窗
				organList: [], //机构列表数据
				pageData: {
					current_page: 0,
					per_page: 0,
					total: 0
				} //机构分页数据
			};
		},
		created() {
			let adminToken = sessionStorage.getItem("adminToken");
			let organizationId=sessionStorage.organizationId
			if (!adminToken || adminToken === undefined||!organizationId) {
				this.$router.push({
					name: "AdminLogin"
				});
				return false;
			} else {
				this.getOrganizationList();
			} //获取机构数据
			if (localStorage.adminName && localStorage.adminName != undefined) {
				this.userName = localStorage.adminName;
			}
			if (sessionStorage.organizationName) {
				this.organizationName = sessionStorage.organizationName;
			}
			if (sessionStorage.organizationImg) {
				this.logo = sessionStorage.organizationImg;
			}
		},
		methods: {
			showselsect(command) {
				if (command === "quit") {
					sessionStorage.removeItem("adminToken");
					sessionStorage.removeItem("organizationName");
					sessionStorage.removeItem("organizationId");
					sessionStorage.removeItem("organizationImg");
					window.open(this.URL.rcmyUrlEmpty + '/#/empty?token=quitToken&name=quitname', '_self')
					this.$router.push({
						name: "AdminLogin"
					});
				} else if (command === "edit") {
					this.$router.push({
						name: "AdminLogin",
						params: {
							type: "updatePassword"
						}
					});
				} else if (command === "changeTl") {
					// 更换机构操作
					this.organDialog = true;
				}
			},
			//关闭组件弹窗事件
			close() {
				this.organDialog = false;
			},
			//获取当前页码
			getPage(data) {
				this.page = data;
				this.getOrganizationList();
			},
			//获取机构数据
			getOrganizationList() {
				this.$http
					.get(this.URL.adminUrl.organizationList, {
						params: {
							page: this.page
						}
					})
					.then(res => {
						this.organList = res.data.data;
						this.pageData = res.data.data.meta;
					})
					.catch(err => {
						error(err.response.data.message);
					});
			},
			//弹框获取点击机构的信息
			getList(data) {
				sessionStorage.organizationId = data.id;
				sessionStorage.organizationName = data.name;
				sessionStorage.organizationImg = data.image;
				this.logo = data.image;
				this.organizationName = data.name;
				this.organDialog = false;
				location.reload(); // 更换机构并刷新
			}
		}
	};
</script>

<style scoped>
.el-menu{
border-right:none;
}
	.el-container{
		width: 100%;
		height: 100%;
	}
	.el-header{
		background-color: #273e55;
	}
	.section{
		height: calc(100% - 60px);
	}
	.el-aside{
		background-color: #273e55;
		color: #ffffff;
		height: 100%;
	}
	.logo_class{
		margin: 10px;
		width: 40px;
		height: 40px;
		display: block;
	}
	.organization_name,.el-dropdown-link{
		color: #ffffff;
	}
</style>

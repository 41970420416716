<template>
  <el-menu router background-color="#273e55" text-color="#fff">
    <el-submenu :index="String(index)" v-for="(item,index) in Menu" :key="index">
      <template slot="title">
        <i :class="item.entity.icon"></i>
        <span>{{item.entity.name}}</span>
      </template>

      <el-menu-item
        :index="child.value"
        v-for="(child,index) in (item.childs)"
        :key="index"
      >{{child.name}}</el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      Menu: [
        {
          entity: {
            icon: "el-icon-s-grid",
            name: "全局管理"
          },
          childs: [
            {
              name: "成员列表",
              value: "/memberList",
              value: "/memberList"
            },
            {
              name: "团队管理",
              value: "/teamManger"
            }
          ]
        },
        {
          entity: {
            icon: "el-icon-s-platform",
            name: "运营管理"
          },
          childs: [
            {
              name: "用户管理",
              value: "/userManagement"
            }
          ]
        },
        {
          entity: {
            icon: "el-icon-setting",
            name: "空间管理"
          },
          childs: [
            // {
            // 	name: "登录列表",
            // 	value: "/loginList",
            // },
            {
              name: "基本信息",
              value: "/AddSpaceOrganization"
            },
            {
              name: "会议室订单",
              value: "/meetingOrder"
            },            
            {
              name: "资产管理",
              value: "/spacePublishing"
            },
            {
              name: "入驻企业",
              value: "/settledEnterprise"
            },
            {
              name: "园区选址",
              value: "/parkLocation"
            },            
            {
              name: "发布科技资源",
              value: "/sendScience"
            },
            {
              name: "发布科研仪器",
              value: "/sendInstrument"
            }
            // {
            // 	name: "合作资源",
            // 	value: "/cooperResoure",
            // }
          ]
        },
        {
          entity: {
            icon: "el-icon-s-home",
            name: "企业集聚"
          },
          childs: [
            {
              name: "政策汇编",
              value: "/policyCompilation"
            },
            {
              name: "招商OA",
              value: "/enterpriseTracking"
            },
            {
              name: "发现企业",
              value: "/discoverCompany"
            },
            {
              name: "访客画像",
              value: "/visitorPortrait"
            },  
            {
              name: "招商分析",
              value: "/enterpriseAnalysis"
            }               
            // {
            // 	name: "企业库",
            // 	value: "/enterpriseLibrary",
            //}
          ]
        }

        // 	{
        // 	entity:{
        // 		icon: "el-icon-setting",
        // 		name: "数据管理"
        // 	},
        // 	childs:[
        // 		{
        // 			name: "数据超市",
        // 			value: "/dataShop",
        // 		}
        // 	]
        // },

        // 	{
        // 	entity:{
        // 		icon: "el-icon-setting",
        // 		name: "运营管理"
        // 	},
        // 	childs:[
        // 		{
        // 			name: "用户管理",
        // 			value: "/userManger",
        // 		}
        // 	]
        // }
      ]
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path;
    }
  }
};
</script>

<style>
</style>
